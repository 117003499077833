import React from "react"
import styled from "styled-components"

import { Container } from "../components/layoutComponents"
import { Row } from "../components/layoutComponents"
import { Column} from "../components/layoutComponents"

import LogoSrc from "../images/frammento_logo_icon.svg"

const Credits = ({ className }) => (
  <div className={className}>
    <img src={LogoSrc} alt="Frammento logo" />
    <div>
      <h3>frammento</h3>
      <span>by <a target="_blank" href="https://samuelerizzo.co">Samuele Rizzo.</a></span>
    </div>
  </div>
)

const StyledCredits = styled(Credits)`
  display: flex;
  align-items: center;

  @media screen and (max-width: 1152px) {
    flex-direction: column;
    align-items: center;
  }

  img {
    width: 64px;
    @media screen and (max-width: 1152px) {
      width: 32px;
      margin-bottom: 0.36rem;
    }
  }

  div {
    margin-left: 0.8rem;

    @media screen and (max-width: 1152px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 0;
    }

    h3 {
      color: var(--primary-color);
      margin-bottom: 0.06rem;
    }

    span {
      color: var(--semi-light-text-color);
      @media screen and (max-width: 1152px) {
        font-size: 12px;
      }
    }
  }
`

const Footer = ({ className }) => (
  <footer className={className}>
    <Row justifyContent="flex-start">
      <StyledCredits />
      {/* <Column alignItems="flex-start" marginColumn className="footerMarginColumn">
        <StyledCredits />
      </Column>

      <Column>
        <Container>
        </Container>
      </Column>

      <Column alignItems="flex-end" className="footerMarginColumn" marginColumn>
        
      </Column> */}

    </Row>
  </footer>
)

const StyledFooter = styled(Footer)`
  margin-top: 6rem;
  color: var(--medium-text-color);
  font-size: 0.86rem;
  padding-top: 1.36rem;
  padding-bottom: 1.36rem;

  & > div {
    padding-left: 1.36rem;

    @media screen and (max-width: 1152px) {
        justify-content: center;
        padding-left: 0;
    }
  }

  // & > div > div is for identifying column div

  // .footerMarginColumn {
  //   width: 100%;
  // }
  
  // @media screen and (max-width: 1152px) {
  //   & > div > div {
  //     padding-top: 0.12rem;
  //     padding-bottom: 0.12rem; 
  //     padding-left: 1rem;
  //     width: 100%;
  //   }
  // }

  // @media screen and (max-width: 768px) {
  //   & > div > div {
  //     padding-left: 1rem;
  //   }
  // }
`

export default StyledFooter
