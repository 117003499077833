import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

function SEO({ description, lang, keywords, title, author, url, twitterUsername }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription = description || data.site.siteMetadata.description
        const metaLongDescription = description || data.site.siteMetadata.longDescription
        const metaTitle = title || data.site.siteMetadata.title
        const metaAuthor = author || data.site.siteMetadata.author
        const metaUrl = url || data.site.siteMetadata.url
        const metaKeywords = keywords || ["frammento", "code", "snippets", "share", "beautiful", "instagram", "images", "social", "source"]
        const metaTwitterUsername = twitterUsername || data.site.siteMetadata.twitterUsername
        const metaImage = data.site.siteMetadata.image
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={metaTitle}
            titleTemplate={`%s - ${data.site.siteMetadata.description}`}
            meta={[
              {
                name: `description`,
                content: metaLongDescription,
              },
              {
                property: `og:title`,
                content: metaTitle + " - " + metaDescription,
              },
              {
                property: `og:site_name`,
                content: metaTitle,
              },
              {
                property: `og:url`,
                content: metaUrl,
              },
              {
                property: `og:image`,
                content: metaUrl + metaImage,
              },
              {
                property: `og:description`,
                content: metaLongDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:author`,
                content: metaAuthor,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: metaTwitterUsername,
              },
              {
                name: `twitter:title`,
                content: metaTitle + " - " + metaDescription,
              },
              {
                name: `twitter:description`,
                content: metaLongDescription,
              },
              {
                name: `twitter:image`,
                content: metaUrl + metaImage,
              },
            ].concat(
              metaKeywords.length > 0 ? {
                      name: `keywords`,
                      content: metaKeywords.join(`, `),
                    } : []
              )}
          />
        )
      }}
    />
  )
}

// SEO.defaultProps = {
//   lang: `en`,
//   meta: [],
//   keywords: [],
// }

// SEO.propTypes = {
//   description: PropTypes.string,
//   lang: PropTypes.string,
//   meta: PropTypes.array,
//   keywords: PropTypes.arrayOf(PropTypes.string),
//   title: PropTypes.string.isRequired,
// }

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        url
        twitterUsername
        author
        image
        longDescription
      }
    }
  }
`
