import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

import { Container } from "../components/layoutComponents"
import { Row } from "../components/layoutComponents"
import { Column} from "../components/layoutComponents"

// Styling share on twitter button
const ShareOnTwitter = styled.a`
  color: var(--medium-text-color);
  background: none;
  border: none;
  border-radius: 7px;
  transition: background 0.3s;
  text-decoration: none;
  font-weight: 500;
  
  @media screen and (max-width: 968px) {
    border-radius: 4px;
  }

  @media screen and (max-width: 812px) {
    position: absolute;
    right: 18px;
  }

  &:hover {
    cursor: pointer;
    background: var(--secondary-color);
    color: var(--medium-text-color);
  }

  &:focus {
    outline: none;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0.6rem;

    @media screen and (max-width: 968px) {
      padding: 0.12rem 0.08rem;
    }

    span {
      @media screen and (max-width: 968px) {
        display: none;
      }
    }

    svg {
      fill: var(--medium-text-color);
      margin-left: 12px;
      height: 18px;

      @media screen and (max-width: 968px) {
        margin-left: 0;
      }
    }

  }
`

const Header = ({ className }) => (
  <header className={className}>

    {/* Structure */}
    <Row justifyContent="space-between">

      {/* Leading column */}
      <Column alignItems="flex-start" className="left-header-column" marginColumn>
        <a href="https://www.producthunt.com/posts/frammento?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-frammento" target="_blank">
          <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=284778&theme=light" alt="frammento - Create and share beautiful images of your code snippets. | Product Hunt" width="194" height="42" />
        </a>
      </Column>

      {/* Center column - Code editor */}
      <Column>
          <Container>
            <Link to="/" className="frammento-logo">
              <h2>frammento</h2>
            </Link>     
          </Container>
      </Column>

      {/* Trailing column - Editor settings */}
      <Column alignItems="flex-end" justifyContent="center" marginColumn>
        <ShareOnTwitter className="shareOnTwitterButton" href="http://twitter.com/intent/tweet?url=https://frammento.co&text=Create%20and%20%23share%20beautiful%20%23code%20%23snippets%20with%20Frammento%20by%20%40samueleFFD613&original_referer=https://frammento.co" target="_blank"> 
          <div>
            <span>Share</span>
            <svg className="svg-icon" viewBox="0 0 20 20">
              <path d="M18.258,3.266c-0.693,0.405-1.46,0.698-2.277,0.857c-0.653-0.686-1.586-1.115-2.618-1.115c-1.98,0-3.586,1.581-3.586,3.53c0,0.276,0.031,0.545,0.092,0.805C6.888,7.195,4.245,5.79,2.476,3.654C2.167,4.176,1.99,4.781,1.99,5.429c0,1.224,0.633,2.305,1.596,2.938C2.999,8.349,2.445,8.19,1.961,7.925C1.96,7.94,1.96,7.954,1.96,7.97c0,1.71,1.237,3.138,2.877,3.462c-0.301,0.08-0.617,0.123-0.945,0.123c-0.23,0-0.456-0.021-0.674-0.062c0.456,1.402,1.781,2.422,3.35,2.451c-1.228,0.947-2.773,1.512-4.454,1.512c-0.291,0-0.575-0.016-0.855-0.049c1.588,1,3.473,1.586,5.498,1.586c6.598,0,10.205-5.379,10.205-10.045c0-0.153-0.003-0.305-0.01-0.456c0.7-0.499,1.308-1.12,1.789-1.827c-0.644,0.28-1.334,0.469-2.06,0.555C17.422,4.782,17.99,4.091,18.258,3.266"></path>
            </svg>
          </div>
        </ShareOnTwitter>
      </Column>

    </Row>

  </header>
)


const StyledHeader = styled(Header)`
  padding-top: 2rem;
  padding-bottom: 2rem;
  position: relative;

  @media screen and (max-width: 968px) {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
  }

  .left-header-column {
    a {
      position: absolute;


      @media screen and (max-width: 1000px) {
        display: none;
      }


    }
  }

  // frammento logo
  .frammento-logo {
    text-decoration: none;
    color: var(--primary-color);
    h2 { 
      font-size: 1.62671rem;
      font-weight: 700;
      margin-bottom: 0; 

      @media screen and (max-width: 768px) {
        font-size: 1.38316rem;
      }
    }
  }
`

export default StyledHeader
