import styled from "styled-components"

export const MainWrapper = styled.div`
  margin: 0;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background: var(--background-color);
  overflow-x: hidden;
  position: relative;
`

export const Container = styled.div`
  //border: 2px solid blue;
  position: relative;
  width: 620px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: ${props => props.paddingBottom ? props.paddingBottom : "initial"};
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`
export const Row = styled.div`
  //border: 2px solid red;
  display: flex;
  flex-direction: row;
  align-items: ${props => props.alignItems ? props.alignItems : "initial"};
  justify-content: ${props => props.justifyContent ? props.justifyContent : "initial"};
  height: ${props => props.height ? props.height : "initial"};
  margin-top: ${props => props.marginTop ? props.marginTop : "initial"};
  @media screen and (max-width: 1152px) {
    flex-direction: ${props => (props.mobile ? "column" : "row")};
  }
`
export const Column = styled.div`
  position: relative; //this is for the settings panel offset
  //border: 2px solid orange;
  width: ${props => (props.marginColumn ? "100%" : "620px")};
  padding-left: ${props => (props.marginColumn ? "1.6em" : "0")};
  padding-right: ${props => (props.marginColumn ? "1.6rem" : "0")};
  display: flex;
  flex-direction: column;
  align-items: ${props => props.alignItems ? props.alignItems : "initial"};
  justify-content: center;
  @media screen and (max-width: 768px) {
    width: ${props => (props.marginColumn ? "0" : "100%")};
    padding-left: ${props => (props.marginColumn ? "0.5rem" : "0")};
    padding-right: ${props => (props.marginColumn ? "0.5rem" : "0")};
  }
`